<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12">
        <h1>Account Setting</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="notification" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Notification" />
        <div class="card">
          <div class="card-header">MOBILE PHONE</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h5 class="card-title">Receive mobile updates by regular SMS text message.</h5>
                <div class="alert alert-info text-justify" role="alert">
                  <b>Note:</b> For more information, text HELP to 247262. To cancel mobile
                  notifications, reply STOP to 247262. Message and Data rates may apply.
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" checked />
                  <label class="form-check-label"> Enable Text Message Notifications </label>
                </div>
                <h5>Receive SMS notifications to:</h5>
                <div class="row">
                  <div class="col-9">
                    <input
                      type="text"
                      class="form-control"
                      id="mobileNumber"
                      placeholder="Enter your mobile number"
                    />
                  </div>
                  <div class="col-3">
                    <button class="btn btn-primary w-100">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">NOTIFY ME WHEN</div>
          <div class="card-body">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" checked />
              <label class="form-check-label">
                I receive a message from another person on Trepr.
              </label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" checked />
              <label class="form-check-label">
                My outstanding Travel service request is accepted or declined.
              </label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" checked />
              <label class="form-check-label"> I receive a Travel service request. </label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" checked />
              <label class="form-check-label">
                Changes are made to my Trepr account or listing.
              </label>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">EMAIL SETTINGS</div>
          <div class="card-body">
            <h5 class="card-title">I want to receive:</h5>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" checked />
              <label class="form-check-label">
                General promotions, updates, news about Trepr or general promotions for partner
                campaigns and services, user surveys, inspiration and love from Trepr.
              </label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" checked />
              <label class="form-check-label"> Reservation and review reminders. </label>
            </div>
            <small class="text-muted">You can disable these at any time.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
import intlTelInput from 'intl-tel-input';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
  methods: {
    // toggleAccountSettingMenuHandle: function () {
    //   console.log('test click 1');
    //   document.getElementById('menuWrapper').classList.toggle('d-none');
    // },
  },
  async mounted() {
    // const inputMobileNumber = document.querySelector('#mobileNumber');
    const inputMobileNumber = document.getElementById('mobileNumber');
    intlTelInput(inputMobileNumber, {
      separateDialCode: true,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
</style>
